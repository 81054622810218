import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import BuddyLogoLongform from './subcomponents/BuddyLogoLongform';

export default function Header() {
	// function classNames(...classes) {
	// 	return classes.filter(Boolean).join(' ');
	// }

	return (
		<Popover className="relative bg-white shadow z-20">
			<div className="max-w-7xl mx-auto px-4 sm:px-6">
				<div className="flex justify-between items-center py-6 lg:justify-start md:space-x-10">
					<div className="flex justify-start mr-20">
						<Link to="/">
							<BuddyLogoLongform height="20" width="108" classN />
						</Link>
					</div>
					<div className="-mr-2 -my-2 lg:hidden">
						<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
							<span className="sr-only">Open menu</span>
							<MenuIcon className="h-6 w-6" aria-hidden="true" />
						</Popover.Button>
					</div>
					<Popover.Group as="nav" className="hidden lg:flex space-x-10">

						<Link to="/#developers" className="body-small-bold ">
							Developers
						</Link>
						<Link to="/#for-section" className="body-small-bold ">
							How It Works
						</Link>
						<Link to="/#insurance" className="body-small-bold ">
							Insurance Companies
						</Link>
						<Link to="/team" className="body-small-bold ">
							About
						</Link>
						<Link to="/contact" className="body-small-bold ">
							Contact
						</Link>
						{/* 12-19-22 Leaving these here bc the idea is to add them back as soon as we have a good solution */}
						{/* <Link to="https://meetings.hubspot.com/dillon-reed" className="buddy-btn-red-nav buddy-red body-small-bold ">
							Book a Demo
						</Link> */}
					</Popover.Group>
				</div>
			</div>

			<Transition
				as={Fragment}
				enter="duration-200 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<Popover.Panel
					focus
					className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right lg:hidden"
				>
					<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
						<div className="pt-5 pb-6 px-5">
							<div className="flex items-center justify-between">
								<div>
									<BuddyLogoLongform height="20" width="108" classN />
								</div>
								<div className="-mr-2">
									<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center">
										<span className="sr-only">Close menu</span>
										<XIcon className="h-6 w-6" aria-hidden="true" />
									</Popover.Button>
								</div>
							</div>
							<div className="mt-6">
								<nav className="grid gap-y-8">
									<Link to="/#developers" className="body-bold -m-3 p-3 flex items-center rounded-md ">
										Developers
									</Link>
									<Link to="/#for-section" className="-m-3 p-3 flex items-center rounded-md body-bold">
										How It Works
									</Link>
									<Link to="/#insurance" className="-m-3 p-3 flex items-center rounded-md body-bold">
										Insurance Companies
									</Link>
									<Link to="/team" className="-m-3 p-3 flex items-center rounded-md body-bold">
										About
									</Link>
									<Link to="/contact" className="-m-3 p-3 flex items-center rounded-md body-bold">
										Contact
									</Link>
									{/* 12-19-22 Leaving these here bc the idea is to add them back as soon as we have a good solution */}
									{/* <Link to="https://meetings.hubspot.com/dillon-reed" className="buddy-btn-mobelux buddy-red body-small-bold ">
										Book a Demo
									</Link> */}
								</nav>
							</div>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	);
}
