import React from 'react';
import { Link } from 'gatsby';
import LinkedInFooter from './subcomponents/LinkedInFooter';
import BuddyIcon from '../assets/BuddyIcon.svg';

let footerAddress;

if (process.env.ACTIVE_ENV === 'production') {
	footerAddress = 'my.buddy.insure';
} else if (process.env.ACTIVE_ENV === 'staging') {
	footerAddress = 'd0-n0t-5har3.buddyinsure.com';
} else if (process.env.ACTIVE_ENV === 'testing') {
	footerAddress = 'my.buddy-testing.insure';
} else {
	footerAddress = 'my.buddy.insure';
}

/* This example requires Tailwind CSS v2.0+ */
const navigation = {
	main: [
		{ name: 'About', href: '/team' },
		{ name: 'Developers', href: '/#developers' },
		{ name: 'How It Works', href: '/#for-section' },
		{ name: 'Insurance Companies', href: '/#insurance' },
		{ name: 'Join Our Team', href: '/jobs' },
		{ name: 'Press', href: '/press' },
		{ name: 'Contact', href: '/contact' },
	],
	social: [
		{
			name: 'Linkedin',
			href: 'https://www.linkedin.com/company/iambuddy/',
			icon: () => (
				<LinkedInFooter />
			),
		},
	],
};

export default function Footer() {
	return (
		<footer className="bg-background2" aria-labelledby="footer-heading">
			<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
				<div className="sm:grid sm:grid-cols-3 sm:gap-8 pb-8">
					<div className="lg:grid grid-cols-2 gap-8 sm:col-span-2">
						<div className="sm:grid sm:grid-cols-2 sm:gap-8">
							<Link to="/">
								<img src={BuddyIcon} alt="buddy icon" />
							</Link>
							<div className="mt-8 sm:mt-0">
								<ul className="space-y-4">
									{navigation.main.map((item) => (
										<li key={item.name} className="w-full">
											<Link to={item.href} className="text-base text-gray-500 hover:text-gray-900">
												{item.name}
											</Link>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="md:grid md:grid-cols-2 md:gap-8" />
					</div>
					<div className="mt-12 sm:mt-0 flex flex-col">
						<h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
							Customer Site
						</h3>
						<a className="text-base text-gray-500 hover:text-gray-900" href={`https://${footerAddress}`}>
							{footerAddress}
						</a>
						<div className="text-base text-gray-500 hover:text-gray-900 mt-4">
							<a
								className=""
								href="tel:+18334628339"
							>
								833.462.8339
							</a>
						</div>
						<div className="text-base text-gray-500 mt-4">
							<span
								className=""
							>
								P.O. Box 81
								<br />
								{' '}
								Richmond, VA 23218-0081
							</span>
						</div>
						<div className="mt-8 md:mt-4">
							<h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
								Social
							</h3>
							{/* Keeping the social below as an array map in case we add more social links back in. */}
							{navigation.social.map((item) => (
								<a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
									<span className="sr-only">{item.name}</span>
									<item.icon className="h-6 w-6" aria-hidden="true" />
								</a>
							))}
						</div>
					</div>
				</div>
				<div className="border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
					<p className="text-base text-gray-400 md:order-1">
						&copy; 2017 -
						{' '}
						{new Date().getFullYear()}
						{' '}
						Buddy Technology, Inc. All Rights Reserved
					</p>
					<div className="flex mt-8 md:mt-0 md:order-2 justify-evenly md:justify-start">
						<Link to="/privacy" className="text-base text-gray-500 hover:text-gray-900">
							Privacy Policy
						</Link>
						<Link to="/terms" className="text-base text-gray-500 hover:text-gray-900 ml-4">
							Terms of Use
						</Link>
					</div>
				</div>
			</div>
		</footer>
	);
}
