import * as React from 'react';
import { Link } from 'gatsby';
import Footer from '../components/Footer';
import Header from '../components/Header';

const NotFoundPage = () => (
	<div className="relative md:h-screen">
		<Header />
		<div className="max-w-7xl mx-auto px-4 sm:px-6 pt-16">
			<title>We're not able to find the page you're looking for.</title>
			<h1>Hmm...</h1>
			<h2>Couldn't find the page you're looking for.</h2>
			<br />
			<p className="pb-20">
				<Link className="underline" to="/">Click here to go back to the homepage.</Link>
				<br />
				<br />
				<Link className="underline" to="/contact">If you're looking for something in particular, click here to get in contact with us.</Link>
			</p>

		</div>
		<Footer />
	</div>
);

export default NotFoundPage;
